import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CondicionIvaService {

  constructor(private http: HttpClient) { }

  obtenerCodnicionIva():Observable<any>{
    const params = new HttpParams().set('funcion', 'listarCondicionIva');
    return this.http.get(`${environment.url.gestionUsuario}`, { params });
  }
}
