import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  private cantidad: BehaviorSubject<string> ;
  public cantidad$: Observable<string> ;
  constructor(private http: HttpClient) {
    this.cantidad = new BehaviorSubject<string>("0");
    this.cantidad$=this.cantidad.asObservable();
  }

  public listarCantidadElementos(idusuario:string){
    const params = new HttpParams().set('funcion', 'listarCantidadCarrito').set('usuario', idusuario);
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params }).subscribe(res=>{
      this.cantidad.next(res.data);
    });
  }

  public agregarElementon(usuario:string,art_codtext:string,art_codnum:number,art_adicional:string,cantidad:number,precio:number):Observable<any>{
    const params = new HttpParams().set('funcion', 'agregarElemento').set('usuario', usuario).set('codtext',art_codtext).set('codnum',art_codnum.toString())
      .set('adicional',art_adicional).set('cantidad',cantidad.toString()).set('precio',precio.toString());
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }
  public modificarElemento(art_codtext: any, art_codnum: any, art_adicional: any, cantidad: any, cliCodigo: string, vta: string, pago: string,transporte:string):Observable<any>{
    const params = new HttpParams().set('funcion', 'modificarElemento')
      .set('art_codtext', art_codtext)
      .set('art_codnum',art_codnum)
      .set('art_adicional',art_adicional)
      .set('cantidad',cantidad)
      .set('cliCodigo',cliCodigo)
      .set('vta',vta)
      .set('pago',pago)
      .set('transporte',transporte)
    ;
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }
  public eliminarElemento(art_codtext: string, art_codnum: string, art_adicional: string, cliCodigo: string):Observable<any>{
    const params = new HttpParams().set('funcion', 'eliminarElemento')
      .set('art_codtext', art_codtext)
      .set('art_codnum', art_codnum)
      .set('art_adicional', art_adicional)
      .set('cli_codigo', cliCodigo)
    ;
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }
  public vaciarCarrito(usuario:string):Observable<any>{
    const params = new HttpParams().set('funcion', 'vaciarCarrito').set('usuario', usuario);
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }

  public cambiarFormaPago(cliCodigo: string,vta: string, pago: string):Observable<any>{
    const params = new HttpParams().set('funcion', 'cambiarFormaPago').set('cli_codigo', cliCodigo)
      .set('vta',vta)
      .set('pago',pago)
    ;
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }

  traerDatosCliente(cli_codigo:string) :Observable<any>{
    const params = new HttpParams().set('funcion', 'traerDatosCliente').set("cli_codigo",cli_codigo);
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }
  public limpiarElemento(){
    this.cantidad.next("0");
  }

  listarElementos(idUsuario: string, cliCodigo: string) {
    const params = new HttpParams().set('funcion', 'listarElementoCarrito').set('usuario', idUsuario).set('cli_codigo',cliCodigo);
    return this.http.get<any>(`${environment.url.gestionPedido}`, { params });
  }


}
