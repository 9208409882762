import {Component, OnDestroy, OnInit} from '@angular/core';
import {CarritoService} from '../../../servicios/pedido/carrito.service';
import {SubscriptionLike} from 'rxjs';

@Component({
  selector: 'app-carrito-btn',
  templateUrl: './carrito-btn.component.html',
  styleUrls: ['./carrito-btn.component.css']
})
export class CarritoBTNComponent implements OnInit, OnDestroy {
  public cantidad:string;

  private sub:SubscriptionLike;

  constructor(private carSV:CarritoService) { }

  ngOnInit(): void {
    this.sub=this.carSV.cantidad$.subscribe(res=>{
      this.cantidad=res;
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
