import { Injectable } from '@angular/core';
import {CanActivate,  Router, ActivatedRoute} from '@angular/router';
import {UserloginService} from '../servicios/usuario/userlogin.service';

@Injectable()
export class NoLoginGuard implements CanActivate {

  constructor(private router: Router,
              private storageService: UserloginService, private routerAC:ActivatedRoute) {
  }

  canActivate(): boolean  {
    if (this.storageService.isAuthenticated()) {
      // logged in so return true
      this.router.navigate(['../home'],{relativeTo:this.routerAC});
      return false;
    }
    // not logged in so redirect to login page

    return true;
  }

}
