import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CompartidoModule} from './compartido/compartido.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NotificacionesService} from './servicios/utilidades/notificaciones.service';
import {CargandoService} from './servicios/utilidades/cargando.service';
import {CargandoInterceptor} from './interceptors/cargando.interceptor';
import {UserloginService} from './servicios/usuario/userlogin.service';
import {LoginService} from './servicios/usuario/login.service';
import {NoLoginGuard} from './guard/no-login.guard';
import {AutorizacionGuard} from './guard/autorizacion.guard';
import {AutorizacionRolGuard} from './guard/autorizacion-rol.guard';
import {UiModule} from './ui/ui.module';
import {CondicionIvaService} from './servicios/usuario/condicion-iva.service';
import {GeneroService} from './servicios/usuario/genero.service';
import {TipoUsuarioService} from './servicios/usuario/tipo-usuario.service';
import {ListaPrecioService} from './servicios/usuario/lista-precio.service';
import {ProductoService} from './servicios/producto/producto.service';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {PedidoService} from './servicios/pedido/pedido.service';
import {registerLocaleData} from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {CatalogoService} from './servicios/soporte/catalogo.service';
import {QuicklinkModule} from 'ngx-quicklink';
import {PublicidadService} from './servicios/publicidad/publicidad.service';
import {CookieService} from "ngx-cookie-service";
import {MatDialogModule} from "@angular/material/dialog";
import {ClienteService} from "./servicios/usuario/cliente.service";
import {GestionTextModalService} from "./servicios/pagina/gestion-text-modal.service";
import {ImagenPaginaService} from "./servicios/pagina/imagen-pagina.service";
import {ProductoImagenService} from "./servicios/producto/producto-imagen.service";
import {GestionArchivoBancosService} from "./servicios/soporte/gestion-archivo-bancos.service";
import {FileUserVipService} from "./servicios/soporte/file-user-vip.service";
registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CompartidoModule,
    HttpClientModule,
    NgbModule,
    UiModule,
    AngularEditorModule,
    CarouselModule,
    QuicklinkModule,
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    NotificacionesService,
    CargandoService, {
      provide: HTTP_INTERCEPTORS,
      useClass: CargandoInterceptor,
      multi: true
    },
    UserloginService,
    LoginService,
    NoLoginGuard,
    AutorizacionGuard,
    AutorizacionRolGuard,
    CondicionIvaService,
    GeneroService,
    TipoUsuarioService,
    ListaPrecioService,
    ProductoService,
    ProductoImagenService,
    PedidoService,
    CatalogoService,
    PublicidadService,
    CookieService,
    ClienteService,
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    GestionTextModalService,
    ImagenPaginaService,
    GestionArchivoBancosService,
    FileUserVipService
  ],
  exports: [],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
