<div class="border-0 d-flex justify-content-center align-items-center">
  <button mat-mini-fab type="button" class="position-absolute m-botton-dialog" [matTooltip]="'Cerrar'" (click)="close()" color="primary">
    <span aria-hidden="true">X</span>
  </button>
  <video  class="m-img-fluid" *ngIf="marketing.isVideo;else link" loop autoplay muted playsinline [defaultMuted]="true" controls preload="auto">
    <source [src]="marketing.urlPublicidad" type="video/mp4">
    <p>Su navegador no soporta vídeos HTML5.</p>
  </video>

</div>

<ng-template #link>
  <ng-container *ngIf="marketing.pubCodtext!='';else imagen">
    <a [routerLink]="['/productos',marketing.pubCodtext,marketing.pubCodnum]" (click)="dialogRef.close()">
      <img class="m-img-fluid" [src]="marketing.urlPublicidad" [alt]="marketing.nombrePublicidad">
    </a>
  </ng-container>
</ng-template>
<ng-template #imagen>
   <img class="m-img-fluid" [src]="marketing.urlPublicidad" [alt]="marketing.nombrePublicidad">
</ng-template>
