import { Component, OnInit } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.css']
})
export class CookiesModalComponent implements OnInit {

  public tecnicas:boolean;
  public personalizacion:boolean;
  public analiticas:boolean;
  public publicitarias:boolean;


  constructor(private cookiesSV:CookieService,private activeModal: NgbActiveModal) {
    this.tecnicas=true;
    this.personalizacion=true;
    this.analiticas=true;
    this.publicitarias=true;
  }

  ngOnInit(): void {
    let config=this.cookiesSV.check('config')?this.cookiesSV.get('config'):'';
    if(config!=''){
      let striSlp=config.split("|");
      this.tecnicas=striSlp[0]=='Y';
      this.personalizacion=striSlp[1]=='Y';
      this.analiticas=striSlp[1]=='Y';
      this.publicitarias=striSlp[2]=='Y';
    }
  }

  rechazar(){
    let config='N|N|N|N';
    this.cookiesSV.set('config',config,{ expires: 180, sameSite: 'Lax',path:'/' });
    this.cookiesSV.set('acceptCookies','Y',{ expires:180, sameSite: 'Lax',path:'/' });
    this.activeModal.close('Exito');
  }


  aceptar(){
    let config='N|N|N|N';
    if(this.tecnicas&&this.publicitarias&&this.personalizacion&&this.analiticas){
       config='Y|Y|Y|Y';
    }
    this.cookiesSV.set('config',config,{ expires: 180, sameSite: 'Lax',path:'/' });
    this.cookiesSV.set('acceptCookies','Y',{ expires:180 , sameSite: 'Lax',path:'/' });
    this.activeModal.close('Exito');
  }

}
