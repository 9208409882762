import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from './servicios/usuario/login.service';
import {UserloginService} from './servicios/usuario/userlogin.service';
import {fromEvent, Observable, SubscriptionLike} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {SwUpdate, UpdateAvailableEvent} from '@angular/service-worker';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RecargarPaginaComponent} from './ui/modals/recargar-pagina/recargar-pagina.component';
import {MarketingComponent} from './ui/modals/marketing/marketing.component';
import {CookieService} from "ngx-cookie-service";
import {CookiesModalComponent} from "./ui/modals/cookies-modal/cookies-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {PublicidadService} from "./servicios/publicidad/publicidad.service";
import {PrimeNGConfig} from "primeng-lts/api";

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Melectricidad SRL';

  public online: boolean;

  private logueado: boolean;
  private subs: SubscriptionLike;
  private subs2: SubscriptionLike;
  private subs3: SubscriptionLike;
  private subscriptions: SubscriptionLike[];
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;

  constructor(private login: LoginService, private userLogin: UserloginService, private router: Router,
              private swUpdate: SwUpdate, private modal: NgbModal, private cookieSV: CookieService,
              public dialog: MatDialog, private publiSV: PublicidadService,private primengConfig: PrimeNGConfig) {
    this.logueado = false;
    this.subs = null;
    this.subs2 = null;
    this.subs3 = null;
    this.subscriptions = [];
    this.online = true;

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(m => m.unsubscribe());

  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.comprobarActualizaciones();
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.online = true;
      console.log('Online...');
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.online = false;
      console.log('Offline...');
    }));
    this.subs = this.userLogin.currentUser.subscribe(res => {
      this.logueado = res != null;
      if (this.logueado) {
        this.verificarSesion();
      }
    });
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    this.subs3 = navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-71KET9YZBY', {
        'page_path': event.urlAfterRedirects
      });
    });


    if (!this.cookieSV.check("acceptCookies") && this.cookieSV.get("acceptCookies") != 'Y') {
      let modalRes = this.modal.open(CookiesModalComponent, {
        size: "xl",
        centered: true,
        scrollable: false,
        backdrop: 'static',
        keyboard: false
      });
      modalRes.result.then((result) => {
        if (!this.cookieSV.check("config") && this.cookieSV.get("acceptCookies") == 'N|N|N|N') {
          window['ga-disable-G-71KET9YZBY'] = true;
        }
      }).finally(() => {
        this.subscriptions.push(this.publiSV.traerPublicidad(1).subscribe(res => {
          this.dialog.open(MarketingComponent, {
            data: res.data,
            panelClass: 'dialog-publicidad',
            backdropClass:'backdrop-publicidad'
          });
        }));

      });
    } else {
      this.subscriptions.push(this.publiSV.traerPublicidad(1).subscribe(res => {
        this.dialog.open(MarketingComponent, {
          data: res.data,
          panelClass: 'dialog-publicidad',
          backdropClass:'backdrop-publicidad'
        });
      }));
      if (!this.cookieSV.check("config") && this.cookieSV.get("acceptCookies") == 'N|N|N|N') {
        window['ga-disable-G-71KET9YZBY'] = true;
      }
    }


  }

  private comprobarActualizaciones() {
    if (this.swUpdate.isEnabled) {
      this.subscriptions.push(
        this.swUpdate.available.subscribe((event: UpdateAvailableEvent) => {
          if (event.current.appData) {
            const appData: any = event.current.appData;
            let titulo = `Nueva version ${appData.version}`;
            const modalRef = this.modal.open(RecargarPaginaComponent, {size: 'sm', centered: true, scrollable: false});
            modalRef.componentInstance.texto = "Se necesita recargar para obtener los nuevos cambios";
            modalRef.componentInstance.titulo = titulo;
            modalRef.result.then((result) => {
              window.location.reload();
            });
          }
        }));
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  muerteApp() {
    if (this.logueado) {
      this.userLogin.actualizarFecha();
    }
    if (this.subs != null) {
      this.subs.unsubscribe();
    }
    if (this.subs2 != null) {
      this.subs2.unsubscribe();
    }
    if (this.subs3 != null) {
      this.subs3.unsubscribe();
    }
    this.subscriptions.forEach(m => m.unsubscribe());
  }

  private verificarSesion() {
    if (this.userLogin.obtenerFecha() != null) {
      let date: Date = new Date();
      let fechaLogin: Date = new Date(this.userLogin.obtenerFecha());
      let diff = Math.abs(date.getTime() - fechaLogin.getTime());
      let min = Math.floor(diff / 60000);
      if (min > 10) {
        this.subs2 =
          this.login.cerrarSesion().subscribe(res => {
            this.logueado = res.status_message != 'Exito';
          });
        this.userLogin.logout();
      }
    } else {
      this.userLogin.actualizarFecha();
    }
  }
}
