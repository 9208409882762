import {Component, TemplateRef} from '@angular/core';
import {NotificacionesService} from '../../servicios/utilidades/notificaciones.service';

@Component({
  selector: 'app-notifiaciones',
  templateUrl: './notifiaciones.component.html',
  styleUrls: ['./notifiaciones.component.css'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class NotifiacionesComponent {

  constructor(public toastService: NotificacionesService) {}

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

}
