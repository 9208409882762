import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {

  constructor(private http: HttpClient) { }

  listarCatalogo():Observable<any>{
    const params = new HttpParams().set('funcion', 'listarCatalogo');
    return this.http.get(`${environment.url.gestionSoporte}`, { params });
  }
  nuevoCatalogo(nombreCatalogo:string,fileImg:any,filePdf:any):Observable<any>{
    const params = new HttpParams()
      .set('funcion', 'nuevoCatalogo')
      .set("nombreCatalogo",nombreCatalogo)
    ;
    const formData = new FormData();
    formData.set('imagen',fileImg);
    formData.set('pdf',filePdf);
    return this.http.post(`${environment.url.gestionSoporte}`, formData,{ params });
  }

  eliminarCatalogo(idCatalogo:number):Observable<any>{
    const params = new HttpParams()
      .set('funcion', 'eliminarCatalogo')
      .set('idCatalogo',String(idCatalogo))
    ;
    return this.http.get(`${environment.url.gestionSoporte}`, { params });
  }

}
