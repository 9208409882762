import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserloginService} from '../../servicios/usuario/userlogin.service';
import {SubscriptionLike} from 'rxjs';
import {Login} from '../../modelo/usuario/login';
import {ParametrosService} from '../../servicios/soporte/parametros.service';

@Component({
  selector: 'app-informacion-utilidad',
  templateUrl: './informacion-utilidad.component.html',
  styleUrls: ['./informacion-utilidad.component.css']
})
export class InformacionUtilidadComponent implements OnInit,OnDestroy {

  private subs:SubscriptionLike[];
  private login:Login
  public precio:string;
  public sesionActiba:boolean;

  constructor(private usSV:UserloginService,private paraSV:ParametrosService) {
    this.subs=[];
    this.sesionActiba=false;
  }

  ngOnInit(): void {
   this.precio="0";
    this.subs.push(this.usSV.currentUser.subscribe(res=>{
      this.sesionActiba = res != null;
      this.login=res

    }));
    this.subs.push(
      this.paraSV.traerParametroCompraMinima().subscribe(res=>{
        this.precio=res.data.precioCompraMinima;
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.forEach(s=>s.unsubscribe());
  }

}
