import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recargar-pagina',
  templateUrl: './recargar-pagina.component.html',
  styleUrls: ['./recargar-pagina.component.css']
})
export class RecargarPaginaComponent  {

  @Input() texto:string;
  @Input() titulo:string;

  constructor(public activeModal: NgbActiveModal) {

  }

}
