import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductoImagenService {

  constructor(private http: HttpClient) { }

  listarImagenProducto(artCodtext:string,artCodnum:number):Observable<any> {
    const params = new HttpParams()
      .set('funcion', 'listarImagenProducto')
      .set('artCodtext', artCodtext)
      .set('artCodnum', ''+artCodnum)
    ;
    return this.http.get(`${environment.url.gestionProductoImagen}`, { params });
  }

  eliminarImagenProducto(idProductoImagen:number):Observable<any> {
    const params = new HttpParams()
      .set('funcion', 'eliminarImagenProducto')
      .set('idProductoImagen', ''+idProductoImagen)
    ;
    return this.http.get(`${environment.url.gestionProductoImagen}`, { params });
  }

  nuevaImagenProducto(file:any,artCodtext:string,artCodnum:number):Observable<any> {
    const formData = new FormData();
    formData.set('imagen',file);
    const params = new HttpParams()
      .set('funcion', 'nuevaImagenProducto')
      .set('artCodtext', artCodtext)
      .set('artCodnum', ''+artCodnum)
    ;
    return this.http.post(`${environment.url.gestionProductoImagen}`,formData, { params });
  }
}
