import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {TextModal} from "../../modelo/modals/text-modal";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable()
export class GestionTextModalService {

  constructor(private http: HttpClient) {
  }

  nuevoTextModal(textModal: TextModal): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'nuevoTextoModal').set('json', JSON.stringify(textModal));
    return this.http.post(`${environment.url.gestionTextModal}`, {}, {params: httpParams});
  }

  modificarTextModal(textModal: TextModal): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'modificarTextoModal').set('json', JSON.stringify(textModal));
    return this.http.post(`${environment.url.gestionTextModal}`, {}, {params: httpParams});
  }

  eliminarTextModal(id: number): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'eliminarTextoModal').set('id', String(id));
    return this.http.delete(`${environment.url.gestionTextModal}`,  {params: httpParams});
  }

  listarTextoModal(seccion: string): Observable<any> {
    let httpParams = new HttpParams().set('funcion', 'listarTextoModal');
    if(seccion !== null){
      httpParams.set('seccion', seccion);
    }
    return this.http.get(`${environment.url.gestionTextModal}`,  {params: httpParams});
  }

  obtenerTextoModal(id: number): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'obtenerTextoModal').set('id', String(id));
    return this.http.get(`${environment.url.gestionTextModal}`,  {params: httpParams});
  }


}
