<div class="modal-header header-modal d-flex justify-content-center align-items-center">
  <h4 class="modal-title">Terminos de Cookies</h4>
</div>
<div class="modal-body">
  <p class="text-justify">Utilizamos cookies de primera parte y de terceros para finalidades analíticas sobre el uso de
    la web, personalizar el contenido en base a tus preferencias, y publicidad personalizada en base a un perfil a
    partir de la recopilación de tus hábitos de navegación. Puedes aceptar todas las cookies mediante el botón "Aceptar"
    o puedes configurar o rechazar su uso.</p>
  <form novalidate>
    <div class="d-flex flex-row justify-content-between align-items-center border-bottom mt-2">
      <div class="w-75">
        <p><b>Cookies técnicas esenciales</b><br>
          Son necesarias para gestionar la navegación dentro de la web o para mantener al usuario conectado. No se
          pueden desactivar porque afectaría al funcionamiento de la web.
        </p>
      </div>
      <div class="w-25">
        <mat-slide-toggle
          class="float-right"
          color="primary"
          [checked]="tecnicas">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center border-bottom mt-2">
      <div class="w-75">
        <p><b>Cookies de personalización</b><br>
          Necesarias para recordar determinadas preferencias del usuario.
        </p>
      </div>
      <div class="w-25">
        <mat-slide-toggle
          class="float-right"
          color="primary"
          [checked]="personalizacion">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center border-bottom mt-2">
      <div class="w-75">
        <p><b>Cookies analíticas</b><br>
          Nos ayudan a nosotros y a terceros a recopilar información sobre el uso de la web, a efectos de realizar un
          análisis estadístico para poder mejorar la estructura y los contenidos.
        </p>
      </div>
      <div class="w-25">
        <mat-slide-toggle
          class="float-right"
          color="primary"
          [checked]="analiticas">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center border-bottom mt-2">
      <div class="w-75">
        <p><b>Cookies publicitarias comportamentales</b><br>
          Ayudan a recopilar los hábitos de navegación del usuario para crear un perfil de sus intereses y para
          mostrarle
          anuncios adaptados a los mismos.</p>
      </div>
      <div class="w-25">
        <mat-slide-toggle
          class="float-right"
          color="primary"
          [checked]="publicitarias">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center mt-2">
      <button class="btn bg-white text-black" (click)="rechazar()"><b>Rechazar todo</b></button>
      <button mat-raised-button color="accent" (click)="aceptar()">Aceptar</button>
    </div>
  </form>
</div>
