<div class="d-flex flex-row flex-wrap justify-content-center align-items-center mb-1">

  <div class="col-md-2 col-12 col-sm-4 row">
    <div class="col-2 col-sm-4 d-flex justify-content-center  align-items-center">
      <i class="fas fa-box fa-3x" style="color: #999"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-7 text-left">
      <h5>
        <strong>Envio a todo el pais</strong>
        <br>
      </h5>
    </div>
  </div>
  <div class="col-md-2 col-12 col-sm-4 row">
    <div class="col-2 col-sm-4 d-flex justify-content-center  align-items-center">
      <i class="fas fa-truck fa-3x" style="color: #999"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-7 text-left">
      <h5>
        <strong>Envios en 24/48HS</strong>
        <br>
        Para pedidos antes de las 13:00h
      </h5>
    </div>
  </div>
  <div class="col-md-2 col-12 col-sm-4 row">
    <div class="col-2 col-sm-4 d-flex justify-content-center  align-items-center">
      <i class="fas fa-redo-alt fa-3x" style="color: #999"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-7 text-left">
      <h5><strong>14 días para las devoluciones</strong></h5>
    </div>
  </div>
  <div class="col-md-2 col-12  col-sm-4 row">
    <div class="col-2 col-sm-4 d-flex justify-content-center  align-items-center">
      <i class="fas fa-shield-alt fa-3x" style="color: #999"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-7 text-left">
      <h5>
        <strong>6 meses de garantia </strong>
      </h5>
    </div>
  </div>
  <div class="col-md-2 col-12  col-sm-4 row">
    <div class="col-2 col-sm-4 d-flex justify-content-center  align-items-center">
      <i class="fas fa-suitcase fa-3x" style="color: #999"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-7 text-left">
      <h5>
        <strong>Descuento por mayor</strong>
        <br>
        <a [routerLink]="['/inicioSesion']" *ngIf="!sesionActiba">Entrar aqui</a>
      </h5>
    </div>
  </div>
</div>
