import { Injectable } from '@angular/core';
import {Login} from '../../modelo/usuario/login';
import {ActivatedRoute, Router} from '@angular/router';
import * as CryptoJS from "crypto-js";
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class UserloginService {
  private localStorageService;
  private currentSession : Login = null;
  private currentUserSubject: BehaviorSubject<Login>;
  public currentUser: Observable<Login>;

  constructor(private router:Router, private routerAc:ActivatedRoute) {
    this.localStorageService = localStorage;
    this.currentUserSubject = new BehaviorSubject<Login>(this.loadSessionData());
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentSession = this.loadSessionData();
  }
  setCurrentSession(session: Login): void {
    this.currentSession = session;
    const ciphertext  = CryptoJS.AES.encrypt(JSON.stringify(session), environment.clave);
    this.localStorageService.setItem('currentUser', ciphertext);
    this.localStorageService.setItem('date', new Date().toISOString());
    this.currentUserSubject.next(session);
  }

  loadSessionData(): Login{
    const sessionStr = this.localStorageService.getItem('currentUser');
    if(sessionStr!=null){
      const bytes  =   CryptoJS.AES.decrypt(sessionStr, environment.clave);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return <Login> JSON.parse(decryptedData);
    }else{
      return null;
    }


  }
  getCurrentSession(): Login {
    return this.currentSession;
  }
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('date');
    this.currentSession = null;
  }


  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null);
  };
  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.nombre) ? session.nombre : null;
  };
  logout(): void{
    this.removeCurrentSession();
    this.currentUserSubject.next(null);
    this.router.navigate(['/inicioSesion'],{relativeTo:this.routerAc});
  }

  actualizarFecha(){
    this.localStorageService.setItem('date', new Date().toISOString());
  }

  obtenerFecha(){
    return this.localStorageService.getItem('date');
  }

  public get currentUserValue(): Login {
    return this.currentUserSubject.value;
  }
}
