import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ImagenPagina} from "../../modelo/pagina/imagen-pagina";

@Injectable({
  providedIn: 'root'
})
export class ImagenPaginaService {

  constructor(private http: HttpClient) {
  }

  nuevaImagenPagina(file: any,imagenPagina: ImagenPagina): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'nuevaImagenPagina').set('json', JSON.stringify(imagenPagina));
    const formData = new FormData();
    formData.set('imagen',file);
    return this.http.post(`${environment.url.gestionPagina}`, formData, {params: httpParams});
  }

  eliminarImagenPagina(seccion: string): Observable<any> {
    const httpParams = new HttpParams().set('funcion', 'eliminarImagenPagina').set('seccion', seccion);
    return this.http.delete(`${environment.url.gestionPagina}`,  {params: httpParams});
  }

  listarImagenPagina(): Observable<any> {
    let httpParams = new HttpParams().set('funcion', 'listarImagenPagina');
    return this.http.get(`${environment.url.gestionPagina}`,  {params: httpParams});
  }
  obtenerImagenPagina(seccion:string): Observable<any> {
    let httpParams = new HttpParams().set('funcion', 'obtenerImagenPagina').set("seccion",seccion);
    return this.http.get(`${environment.url.gestionPagina}`,  {params: httpParams});
  }

  modificarImagen(idImagenPagina: number, enabled: boolean): Observable<any> {
    let httpParams = new HttpParams().set('funcion', 'modificarEstado').set("idImagenPagina",""+idImagenPagina).set("enabled",""+enabled);
    return this.http.get(`${environment.url.gestionPagina}`,  {params: httpParams});
  }
}
