import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  iniciarSesion(email:string, contrasenia:string): Observable<any>{
    const params = new HttpParams().set('funcion', 'loggin').set('correo', email).set("contrasenia",contrasenia);
    return this.http.post(`${environment.url.gestionLogin}`,{},{params});
  }

  cerrarSesion(): Observable<any>{
    const params = new HttpParams().set('funcion', 'cerrarSesion');
    return this.http.post(`${environment.url.gestionLogin}`,{},{params});
  }
}
