import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {FileUserVip} from "../../modelo/soporte/file-user-vip";

@Injectable({
  providedIn: 'root'
})
export class FileUserVipService {

  constructor(private http: HttpClient) { }

  traerFileUserVips():Observable<any>{
    const params = new HttpParams().set('funcion', 'traerFileUserVips');
    return this.http.get(`${environment.url.gestionFileUserVips}`, { params });
  }

  eliminarFileUserVips(fuvId:number):Observable<any>{
    const params = new HttpParams().set('funcion', 'eliminarFileUserVips').set("fuvId",""+fuvId);
    return this.http.get(`${environment.url.gestionFileUserVips}`, { params });
  }

  crearFileUserVips(fuv:FileUserVip,file:any):Observable<any>{
    const formData = new FormData();
    formData.set('file',file);
    const body = JSON.stringify({fuv});
    formData.set('fuv',body);
    const params = new HttpParams().set('funcion', 'crearFileUserVips');
    return this.http.post(`${environment.url.gestionFileUserVips}`, formData,{ params });
  }
  modificarFileUserVips(fuv:FileUserVip):Observable<any>{
    const body = JSON.stringify({fuv});
    const params = new HttpParams().set('funcion', 'modificarFileUserVips');
    return this.http.post(`${environment.url.gestionFileUserVips}`, body,{ params });
  }
}
