import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) {
  }

  traerClientes(): Observable<any> {
    const params = new HttpParams().set('funcion', 'listarClientes');
    return this.enviarSolicitud(params);
  }

  enviarSolicitud(params: HttpParams): Observable<any> {
    return this.http.post(`${environment.url.gestionCliente}`, {}, {params});
  }
}
