<section id="cabecera" class="container-fluid cabecera">
  <div class="d-flex flex-wrap flex-row justify-content-start align-items-center">
    <div class="col-lg-1 text-center">
      <img src="./assets/imagen/Galeria/LogoNuevoAzul.png" class="logo cursor-pointer div-logo" routerLink="home">
    </div>
    <div class="col-lg-2"></div>
    <div class="col-lg-6">
      <div class="d-flex flex-row flex-row justify-content-center align-items-center" style="height: 66px">
        <div class="input-group">
          <input class="form-control"
                 placeholder="Ingrese un Nombre"
                 aria-label="Nombre"
                 [matAutocomplete]="auto"
                 [formControl]="nombresIngresado"
                 [(ngModel)]="textoBuscar"
          >
          <div class="input-group-append">
            <button class="btn btn-secondary bg-white text-naranja btn-buscador" type="button" (click)="buscar()"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValueChange($event)" [displayWith]="displayFn">
          <mat-option *ngIf="cargando" class="is-loading" disabled>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-option>
          <ng-container *ngIf="!cargando">
            <mat-option *ngFor="let opt of respuesta" [value]="opt.art_descri">
              {{opt.art_descri}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>

    </div>
  </div>
</section>

