import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GestionArchivoBancosService {

  constructor(private http: HttpClient) { }

  subirArchivosBanco(archivo: any, imagen: any):Observable<any>{
    const formData = new FormData();
    formData.set('archivo',archivo);
    formData.set('image',imagen);
    const params = new HttpParams().set('funcion', 'subirArchivosBanco');
    return this.http.post(`${environment.url.gestionSoporte}`,formData, { params });
  }
}
