import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  constructor(private http: HttpClient) { }

  listarParametros():Observable<any>{
    const params = new HttpParams().set('funcion', 'listarParametros');
    return this.http.get(`${environment.url.gestionSoporte}`, { params });
  }
  modificarParametros(parametros:any):Observable<any>{

    const params = new HttpParams().set('funcion', 'modificarParametros').set('parametro',JSON.stringify(parametros));
    return this.http.get(`${environment.url.gestionSoporte}`, { params });
  }


  traerParametroCompraMinima():Observable<any>{
    const params = new HttpParams().set('funcion', 'traerParametroCompraMinima');
    return this.http.get(`${environment.url.gestionSoporte}`, { params });
  }
}
