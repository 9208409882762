import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TerminosCondicionesComponent} from './modals/terminos-condiciones/terminos-condiciones.component';
import {VerUsuarioComponent} from './modals/gestion-usuario/ver-usuario/ver-usuario.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AmUsuarioModalComponent} from './modals/gestion-usuario/am-usuario-modal/am-usuario-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClicodigoModalComponent} from './modals/gestion-usuario/clicodigo-modal/clicodigo-modal.component';
import {AsignarEstadoUsuarioComponent} from './modals/gestion-usuario/asignar-estado-usuario/asignar-estado-usuario.component';
import {ConfirmacionComponent} from './modals/confirmacion/confirmacion.component';
import {ArticuloDescripcionComponent} from './modals/gestion-producto/articulo-descripcion/articulo-descripcion.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {ArticuloVideoComponent} from './modals/gestion-producto/articulo-video/articulo-video.component';
import {ArticuloCertificadoComponent} from './modals/gestion-producto/articulo-certificado/articulo-certificado.component';
import {AMCarIndexComponent} from './modals/gestion-soporte/amcar-index/amcar-index.component';
import {NCarMarcaComponent} from './modals/gestion-soporte/ncar-marca/ncar-marca.component';
import {NCarTarjetaModalComponent} from './modals/gestion-soporte/n-car-tarjeta-modal/n-car-tarjeta-modal.component';
import {NCarConsultaComponent} from './modals/gestion-soporte/ncar-consulta/ncar-consulta.component';
import {MarcasCarruselComponent} from './carrusel/marcas-carrusel/marcas-carrusel.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TarjetaCarruselComponent} from './carrusel/tarjeta-carrusel/tarjeta-carrusel.component';
import {OfertaPDFCarruselComponent} from './carrusel/oferta-pdfcarrusel/oferta-pdfcarrusel.component';
import {RouterModule} from '@angular/router';
import {ProductoRelacionadoComponent} from './productos/producto-relacionado/producto-relacionado.component';
import {ProductoTemplateComponent} from './productos/producto-template/producto-template.component';
import {ProductoOfertaCarruselComponent} from './carrusel/producto-oferta-carrusel/producto-oferta-carrusel.component';
import {ConsultaPrecioCarruselComponent} from './carrusel/consulta-precio-carrusel/consulta-precio-carrusel.component';
import {IrArribaComponent} from './botones/ir-arriba/ir-arriba.component';
import {ConsultarPrecioBTNComponent} from './botones/consultar-precio-btn/consultar-precio-btn.component';
import {CarritoBTNComponent} from './botones/carrito-btn/carrito-btn.component';
import {MatBadgeModule} from '@angular/material/badge';
import {EspacerComponent} from './espacer/espacer.component';
import {WhatsappBTNComponent} from './botones/whatsapp-btn/whatsapp-btn.component';
import {VerImagenComponent} from './modals/ver-imagen/ver-imagen.component';
import {NuevoCatalogoComponent} from './modals/gestion-soporte/nuevo-catalogo/nuevo-catalogo.component';
import { RecargarPaginaComponent } from './modals/recargar-pagina/recargar-pagina.component';
import { MarketingComponent } from './modals/marketing/marketing.component';
import { CookiesModalComponent } from './modals/cookies-modal/cookies-modal.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { McarMarcaComponent } from './modals/gestion-soporte/mcar-marca/mcar-marca.component';
import { MarcaDescripcionEmergenteComponent } from './modals/marca-descripcion-emergente/marca-descripcion-emergente.component';
import { GestionAmModalsComponent } from './modals/gestion-am-modals/gestion-am-modals.component';
import { ModalsUniversalComponent } from './modals/modals-universal/modals-universal.component';
import { GestionImagenAltaComponent } from './modals/gestion-imagen-alta/gestion-imagen-alta.component';
import { ProductoInicioComponent } from './productos/producto-inicio/producto-inicio.component';
import { ProductosQRComponent } from './modals/productos-qr/productos-qr.component';
import {QRCodeModule} from "angular2-qrcode";
import {NgxPrintModule} from "ngx-print";
import { AmChipsComponent } from './modals/gestion-producto/am-chips/am-chips.component';
import { NuevoArchivoVipComponent } from './modals/nuevo-archivo-vip/nuevo-archivo-vip.component';

@NgModule({
  declarations: [
    TerminosCondicionesComponent,
    VerUsuarioComponent,
    AmUsuarioModalComponent,
    ClicodigoModalComponent,
    AsignarEstadoUsuarioComponent,
    ConfirmacionComponent,
    ArticuloDescripcionComponent,
    ArticuloVideoComponent,
    ArticuloCertificadoComponent,
    AMCarIndexComponent,
    NCarMarcaComponent,
    NCarTarjetaModalComponent,
    NCarConsultaComponent,
    MarcasCarruselComponent,
    TarjetaCarruselComponent,
    OfertaPDFCarruselComponent,
    ProductoRelacionadoComponent,
    ProductoTemplateComponent,
    ProductoOfertaCarruselComponent,
    ConsultaPrecioCarruselComponent,
    IrArribaComponent,
    ConsultarPrecioBTNComponent,
    CarritoBTNComponent,
    EspacerComponent,
    WhatsappBTNComponent,
    VerImagenComponent,
    NuevoCatalogoComponent,
    RecargarPaginaComponent,
    MarketingComponent,
    CookiesModalComponent,
    McarMarcaComponent,
    MarcaDescripcionEmergenteComponent,
    GestionAmModalsComponent,
    ModalsUniversalComponent,
    GestionImagenAltaComponent,
    ProductoInicioComponent,
    ProductosQRComponent,
    AmChipsComponent,
    NuevoArchivoVipComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    AngularEditorModule,
    FormsModule,
    MatListModule,
    MatTooltipModule,
    CarouselModule,
    RouterModule,
    MatBadgeModule,
    MatSlideToggleModule,
    QRCodeModule,
    NgxPrintModule
  ],
  exports: [TerminosCondicionesComponent,
    VerUsuarioComponent,
    AmUsuarioModalComponent,
    ClicodigoModalComponent,
    AsignarEstadoUsuarioComponent,
    ConfirmacionComponent,
    ArticuloDescripcionComponent,
    ArticuloVideoComponent,
    ArticuloCertificadoComponent,
    AMCarIndexComponent,
    NCarMarcaComponent,
    NCarTarjetaModalComponent,
    NCarConsultaComponent,
    MarcasCarruselComponent,
    TarjetaCarruselComponent,
    OfertaPDFCarruselComponent,
    ProductoRelacionadoComponent,
    ProductoTemplateComponent,
    ProductoOfertaCarruselComponent,
    ConsultaPrecioCarruselComponent,
    IrArribaComponent,
    ConsultarPrecioBTNComponent,
    CarritoBTNComponent,
    EspacerComponent,
    WhatsappBTNComponent,
    VerImagenComponent,
    NuevoCatalogoComponent,
    RecargarPaginaComponent,
    MarketingComponent,
    CookiesModalComponent,
    McarMarcaComponent,
    MarcaDescripcionEmergenteComponent,
    GestionAmModalsComponent,
    ModalsUniversalComponent,
    GestionImagenAltaComponent,
    ProductoInicioComponent,
    ProductosQRComponent,
    AmChipsComponent,
    NuevoArchivoVipComponent
  ],
  entryComponents: [TerminosCondicionesComponent,
    VerUsuarioComponent,
    AmUsuarioModalComponent,
    ClicodigoModalComponent,
    AsignarEstadoUsuarioComponent,
    ConfirmacionComponent,
    ArticuloDescripcionComponent,
    ArticuloVideoComponent,
    ArticuloCertificadoComponent,
    AMCarIndexComponent,
    NCarMarcaComponent,
    NCarTarjetaModalComponent,
    NCarConsultaComponent,
    MarcasCarruselComponent,
    TarjetaCarruselComponent,
    ProductoOfertaCarruselComponent,
    ConsultaPrecioCarruselComponent,
    VerImagenComponent,
    NuevoCatalogoComponent,
    RecargarPaginaComponent,
    MarketingComponent,
    CookiesModalComponent,
    McarMarcaComponent,
    MarcaDescripcionEmergenteComponent,
    GestionAmModalsComponent,
    GestionImagenAltaComponent,
    AmChipsComponent,
    NuevoArchivoVipComponent
  ],
})
export class UiModule {
}
