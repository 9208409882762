import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {UserloginService} from '../servicios/usuario/userlogin.service';

@Injectable({
  providedIn: 'root'
})
export class AutorizacionRolGuard implements CanActivate {

  constructor(private router: Router,
              private storageService: UserloginService, private routerAC: ActivatedRoute) {
  }

  canActivate(): boolean {
    if (this.storageService.isAuthenticated()) {
      const user = this.storageService.getCurrentSession().usuario;
      if (user=='Admin') {
        // logged in so return true
        return true;
      }
      this.router.navigate(['/home'], {relativeTo: this.routerAC});
      return false;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/inicioSesion'], {relativeTo: this.routerAC});
    return false;
  }

}
