<div *ngIf="online;else offline">
  <div class="fixed-top">
    <app-cabecera class="cabecera"></app-cabecera>
    <app-menu></app-menu>
  </div>
  <app-cargando></app-cargando>
  <main>
    <app-notifiaciones></app-notifiaciones>
    <router-outlet></router-outlet>
  </main>
  <hr class="gruesa">
  <app-informacion-utilidad></app-informacion-utilidad>
  <app-pie-pagina></app-pie-pagina>
  <app-ir-arriba [acceleration]="1" [animate]="true" [scrollDistance]="200" [speed]="40"></app-ir-arriba>
</div>

<ng-template #offline>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <h3>No hay conexión a internet</h3>
    <p class="text-info">Intentelo mas tarde</p>
  </div>
</ng-template>
