import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PaginaNoEncontradaComponent} from './compartido/pagina-no-encontrada/pagina-no-encontrada.component';
import {QuicklinkStrategy} from 'ngx-quicklink';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./core/home/home.module').then(m => m.HomeModule),
    pathMatch:'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./core/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'inicioSesion',
    loadChildren: () => import('./core/auth/inicio-sesion/sesion.module').then(m => m.SesionModule)
  },
  {
    path: 'registrarse',
    loadChildren: () => import('./core/registrarse/registrarse.module').then(m => m.RegistrarseModule)
  },
  {
    path: 'olvidoContrasenia',
    loadChildren: () => import('./core/olvido-contrasenia/olvido-contrasenia.module').then(m => m.OlvidoContraseniaModule)
  },
  {
    path: 'usuario',
    loadChildren: () => import('./core/auth/usuario/seccion-usuario.module').then(m => m.SeccionUsuarioModule)
  },
  {
    path: 'administracion',
    loadChildren: () => import('./core/auth/administracion/seccion-administracion.module').then(m => m.SeccionAdministracionModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./core/producto/producto.module').then(m => m.ProductoModule)
  },
  {
    path: 'deseados',
    loadChildren: () => import('./core/auth/producto/deseados/deseados.module').then(m => m.DeseadosModule)
  },
  {
    path: 'pedido/pago/:accion',
    loadChildren: () => import('./core/auth/pedido/pedido.module').then(m => m.PedidoModule)
  },
  {
    path: 'contactanos',
    loadChildren: () => import('./core/contactanos/contactanos.module').then(m => m.ContactanosModule)
  },
  {
    path: 'presupuesto',
    loadChildren: () => import('./core/presupuesto-obra/presupuesto-obra.module').then(m => m.PresupuestoObraModule)
  },
  {
    path: 'sobreNosotros',
    loadChildren: () => import('./core/sobre-nosotros/sobre-nosotros.module').then(m => m.SobreNosotrosModule)
  },
  {
    path: 'pagosyenvios',
    loadChildren: () => import('./core/auth/pago-envios/pago-envios.module').then(m => m.PagoEnviosModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('./core/auth/pago-envios/pago-envios.module').then(m => m.PagoEnviosModule)
  },
  {
    path: '**',
    component: PaginaNoEncontradaComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ preloadingStrategy: QuicklinkStrategy, scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
