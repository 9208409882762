import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Publicidad} from '../../../modelo/publicidad/publicidad';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})
export class MarketingComponent {

  public marketing:Publicidad;

  constructor(public dialogRef: MatDialogRef<MarketingComponent>,@Inject(MAT_DIALOG_DATA) public data: Publicidad) {
    this.marketing=data;
  }

  close(): void {
    this.dialogRef.close();
  }


}
