export const environment = {
  production: true,
  url:{
    gestionUsuario: "api/gestionUsuario/GestorUsuario.php",
    gestionCliente: "api/gestionUsuario/GestorCliente.php",
    gestionLogin: "api/gestionUsuario/GestorLogin.php",
    gestionProducto: "api/gestionProducto/GestorProducto.php",
    gestionProductoImagen: "api/gestionProducto/GestorProductoImagen.php",
    gestionPedido: "api/gestionPedido/GestorPedido.php",
    gestionSoporte: "api/soporte/GestorSoporte.php",
    gestionPublicidad: "api/marketing/GestorPublicidad.php",
    gestionTextModal: "api/gestionModals/GestorTextModal.php",
    gestionPagina: "api/gestionPagina/GestorPagina.php",
    gestionFileUserVips: "api/soporte/GestorFileUserVips.php"
  },
  clave:"encriptameesta",
  claveSHA256:"loginm",
  host: "https://melectricidad.com.ar/api/productoQr.php?art_codtext=#text#&art_codnum=#num#"
};
