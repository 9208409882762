import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CabeceraComponent} from './cabecera/cabecera.component';
import {PiePaginaComponent} from './pie-pagina/pie-pagina.component';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import {NotifiacionesComponent} from './notifiaciones/notifiaciones.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CargandoComponent } from './cargando/cargando.component';
import {PaginaNoEncontradaComponent} from './pagina-no-encontrada/pagina-no-encontrada.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {UiModule} from '../ui/ui.module';
import { InformacionUtilidadComponent } from './informacion-utilidad/informacion-utilidad.component';
import {QRCodeModule} from "angular2-qrcode";



@NgModule({
  declarations: [CabeceraComponent,PiePaginaComponent, MenuComponent,NotifiacionesComponent, CargandoComponent,PaginaNoEncontradaComponent, InformacionUtilidadComponent],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatIconModule,
        RouterModule,
        MatButtonModule,
        NgbModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatBadgeModule,
        MatProgressBarModule,
        UiModule,
        QRCodeModule
    ],
    exports: [CabeceraComponent, PiePaginaComponent, MenuComponent, NotifiacionesComponent, CargandoComponent, PaginaNoEncontradaComponent, InformacionUtilidadComponent]
})
export class CompartidoModule { }
