import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-consultar-precio-btn',
  templateUrl: './consultar-precio-btn.component.html',
  styleUrls: ['./consultar-precio-btn.component.css']
})
export class ConsultarPrecioBTNComponent implements OnInit {

  @Input() mostrar:boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

}
