import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp-btn',
  templateUrl: './whatsapp-btn.component.html',
  styleUrls: ['./whatsapp-btn.component.css']
})
export class WhatsappBTNComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
