import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(private http: HttpClient) { }

  listarArticulos():Observable<any> {
    const params = new HttpParams().set('funcion', 'gsProducto');
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  esNovedad(checked: boolean, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'esNovedad').set('novedad',checked?'1':'0').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  esTop(checked: boolean, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarIsTop').set('isTop',checked?'1':'0').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  esAgotado(checked: boolean, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarAgotado').set('agotado',checked?'1':'0').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  esPreciVisible(checked: boolean, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarPrecioVisible').set('precioVisible',checked?'1':'0').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  isProximamente(checked: boolean, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarProximamente').set('proximamente',checked?'1':'0').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  chips(chips: string, art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarChips').set('chips',chips).set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  eliminarArticulo(art_codtex: string, art_codnum: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'eliminarArticulo').set('art_codtex',art_codtex).set('art_codnum',art_codnum);
    return this.http.get(`${environment.url.gestionProducto}`, { params });

  }

  guardarDescripcion(cod_text: string, cod_num: string, descripcion: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarDescripcion').set('art_codtex',cod_text).set('art_codnum',cod_num).set("descripcion", descripcion);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  guardarVideo(cod_text: string, cod_num: string, urlVideo: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'guardarVideo').set('art_codtex',cod_text).set('art_codnum',cod_num).set("urlVideo", urlVideo);
    return this.http.get(`${environment.url.gestionProducto}`, { params });

  }

  cargarCertificado(cod_text: string, cod_num: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'cargarCertificado').set('art_codtex',cod_text).set('art_codnum',cod_num);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  eliminarCertificado(id_certificado: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'eliminarCertificado').set('id',id_certificado);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  guardarCertificado(cod_text:string,cod_num:string,file: any):Observable<any> {
    const formData = new FormData();
    formData.set("cert",file);
    const params = new HttpParams().set('funcion', 'guardarCertificado').set('art_codtex',cod_text).set('art_codnum',cod_num);
    return this.http.post(`${environment.url.gestionProducto}`, formData,{ params });
  }

  traerCategoria():Observable<any> {
    const params = new HttpParams().set('funcion', 'traerCategorias');
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  listarCategoriasWeb():Observable<any> {
    const params = new HttpParams().set('funcion', 'listarCategoriasWeb');
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  traerMenuCategoria():Observable<any> {
    const params = new HttpParams().set('funcion', 'traerMenuCategorias');
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  armarMenu(t:string,l:string,r:string,f:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'armarMenu').set("T",t).set("L",l).set("R",r).set("F",f);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }
  armarMigaDePan(t:string,l:string,r:string,f:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'armarBreadcrumb').set("T",t).set("L",l).set("R",r).set("F",f);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

    traerProductos(t: string, l: string, r: string, f: string, busc: string, marca: string, clicodigo: string, paginaActual: number, cantXPagina: number):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerProductos').set("T",t).set("L",l).set("R",r).set("F",f).set("busqueda",busc).set("marca",marca).set("clicodigo",clicodigo).set("axp",''+cantXPagina).set('pag',''+paginaActual);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  traerNovedades(clicodigo: string, paginaActual: number, cantXPagina: number):Observable<any> {
      const params = new HttpParams().set('funcion', 'traerNovedades').set("clicodigo",clicodigo).set("axp",''+cantXPagina).set('pag',''+paginaActual);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  traerProductoTag(clicodigo: string,tag:string, paginaActual: number, cantXPagina: number):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerProductoTag').set("clicodigo",clicodigo).set("tag",tag).set("axp",''+cantXPagina).set('pag',''+paginaActual);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  traerOfertas(clicodigo: string, paginaActual: number, cantXPagina: number):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerOfertas').set("clicodigo",clicodigo).set("axp",''+cantXPagina).set('pag',''+paginaActual);
    return this.http.get(`${environment.url.gestionProducto}`, { params });

  }

  traerPoliticaVentas(clicodigo: string, paginaActual: number, cantXPagina: number) :Observable<any> {
    const params = new HttpParams().set('funcion', 'traerPoliticaVentas').set("clicodigo",clicodigo).set("axp",''+cantXPagina).set('pag',''+paginaActual);
    return this.http.get(`${environment.url.gestionProducto}`, { params });

  }

  consultarProducto(clicodigo: string, art_codtext: string, art_codnum: string,idUsuario:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'consultarProducto').set("clicodigo",clicodigo).set("art_codtext",art_codtext).set('art_codnum',art_codnum).set("idUsuario",idUsuario);
    return this.http.get(`${environment.url.gestionProducto}`, { params });

  }

  traerProductosTop(tipoProd: string, linea: string, clicodigo: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerProductosTop').set("tipoProd",tipoProd).set('linea',''+linea).set("clicodigo",clicodigo);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  traerMarcas():Observable<any> {
    const params = new HttpParams().set('funcion', 'traerMarcas');
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  traerLinea(id: string) :Observable<any> {
    const params = new HttpParams().set('funcion', 'traerlinea').set('idCategoria', id);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  listarNombresProducto(text:string,clicodigo:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'listarNombresProducto').set('clicodigo',clicodigo).set('text',text);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  listarNombresProductoWeb(text:string,clicodigo:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'listarNombresProductoWeb').set('clicodigo',clicodigo).set('text',text);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  listarNombresProductoWebAdmin(text:string,clicodigo:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'listarNombresProductoWebAdmin').set('clicodigo',clicodigo).set('text',text);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }
  listarCodigoProducto(text:string,clicodigo:string):Observable<any> {
    const params = new HttpParams().set('funcion', 'listarCodigoProducto').set('clicodigo',clicodigo).set('text',text);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  listarProductosConsultaPrecio(busqueda: any, cliCodigo: string, idUsuario: string):Observable<any>{
    const params = new HttpParams().set('funcion', 'listarProductosConsultaPrecio').set('clicodigo',cliCodigo).set('idusuario',idUsuario).set("busqueda",JSON.stringify(busqueda));
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  traerProductosGestion(cliCodigo: string, categoria: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerProductosGestion').set('clicodigo',cliCodigo).set("tipProd",categoria);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }

  traerProductosInicio(cliCodigo: string, categoria: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'traerProductosTopInicio').set('clicodigo',cliCodigo).set("tipoProd",categoria);
    return this.http.get(`${environment.url.gestionProducto}`, {params});
  }
}
