<header class="navbar navbar-expand-xl  navbar-custom bg-custom">
  <img src="./assets/imagen/Galeria/LogoNuevoAzul.png" class="logo-menu  div-logo2 cursor-pointer" routerLink="home" alt="logo mini">
  <div class="d-flex justify-content-end align-items-center">
    <a class="btn-carrito" type="button"  [routerLink]="['usuario','carrito']" [matTooltip]="'Ir a carrito'"
       style="font-size: 18px;" matTooltipPosition="left">
      <mat-icon [matBadge]="cantidadCarrito" color="accent" style="color: white;">
        <i class="fas fa-shopping-cart"></i>
      </mat-icon>
    </a>
    <a class="a-buscador text-white" type="button" data-toggle="collapse" data-target="#navbarbuscador"
       aria-controls="navbarbuscador" aria-expanded="false" aria-label="Buscador">
      <i class="fas fa-search"></i>
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>
  <div class="container-fluid">
    <div class="collapse w-100" id="navbarbuscador">
      <div class="input-group">
        <input class="form-control"
               placeholder="Ingrese un Nombre"
               aria-label="Nombre"
          [matAutocomplete]="auto"
          [formControl]="nombresIngresado"
          [(ngModel)]="textoBuscar"
        >
        <div class="input-group-append">
          <button class="btn btn-sm bg-white" (click)="buscar()">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setValueChange($event)" [displayWith]="displayFn">
          <mat-option *ngIf="cargando" class="is-loading" disabled>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </mat-option>
          <ng-container *ngIf="!cargando">
            <mat-option *ngFor="let opt of respuesta" [value]="opt.art_descri">
              {{opt.art_descri}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </div>
    <div class="collapse navbar-collapse  font-size-menu" id="navbarSupportedContent">
      <div class="col-lg-3"></div>
      <div class="col-lg-9 justify-content-start">
        <ul class="navbar-nav">
          <li class="nav-item categoria-cell">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="productos">
              CATEGORIAS
            </a>
          </li>
          <li class="nav-item dropdown cursor-pointer position-static categoria">
            <a class="nav-link dropdown-toggle font-size-menu" id="navbarDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              CATEGORIAS
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a
                class="dropdown-item font-size-menu-drop" data-toggle="collapse" data-target=".navbar-collapse.show"
                style="white-space: normal;" [class.active]="this.catSelect!=null && this.catSelect.id==cat.id" [class.categoria-sec]="this.catSelect!=null && this.catSelect.id==cat.id" *ngFor="let cat of categoria"
                routerLink="productos" [queryParams]="{T:cat.id}" (click)="catSelect=null">
                {{cat.descri}}
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="productos/marcas">MARCAS</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="productos/novedades">NOVEDADES</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="productos/politicaVenta">PROMOCIONES</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="productos/ofertas">LIQUIDACIÓN</a>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/presupuesto']">PRESUPUESTO</a>
          </li>
          <li class="nav-item dropdown cursor-pointer">
            <a class="nav-link dropdown-toggle font-size-menu" id="navbarDropdown2" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              EMPRESA
            </a>
            <div class="dropdown-menu font-size-menu-drop" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/sobreNosotros']">INFORMACIÓN</a>
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/contactanos']">CONTACTO</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="deseados">
              <mat-icon  [matBadge]="cantidad" matBadgeColor="warn">favorite</mat-icon>
            </a>
          </li>
          <li class="nav-item" *ngIf="!inicioSesion" [routerLinkActive]="['active']">
            <a class="nav-link font-size-menu" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="inicioSesion">INGRESAR</a>
          </li>
          <li class="nav-item dropdown menu-login cursor-pointer" *ngIf="inicioSesion">
            <a class="nav-link dropdown-toggle font-size-menu" data-toggle="dropdown" role="button" aria-haspopup="true"
               aria-expanded="false">{{currentUser.nombre | uppercase}}</a>
            <div class="dropdown-menu font-size-menu-drop" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="usuario/miPerfil">MI CUENTA</a>
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/pagosyenvios']">PAGOS Y ENVIOS</a>
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['/usuario','cambiarContraseña']">CAMBIAR CONTRASEÑA</a>
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" [hidden]="!isAdmin" routerLink="administracion/gestionUsuario">ADMINISTRACION</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="cerrarSion()">CERRAR SESION</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
<app-consultar-precio-btn [mostrar]="inicioSesion"></app-consultar-precio-btn>
<app-carrito-btn></app-carrito-btn>
<app-whatsapp-btn></app-whatsapp-btn>
