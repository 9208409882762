<section class="bg-fondo">
  <div class="container h-100">
    <div class="row align-items-center text-center text-dorado-pelu h-100">
      <div class="col-lg-6">
        <h2 class="titulos">Lo Sentimos</h2>
        <p class="text-justify">La página que estas buscando no se encuentra disponible <a [routerLink]="['/home']">Click Aquí</a> para volver al inicio</p>
      </div>
      <div class="col-lg-6 ">
        <div class="noFount">
          <img src="assets/imagen/Galeria/LogoNuevoColor.png" class="img-fluid" alt="Logo" width="440" height="140">
        </div>
      </div>
    </div>
  </div>
</section>
