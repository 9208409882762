<footer class="footer-fixed">
  <div class="container padding-footer">
    <div class="d-flex flex-wrap flex-row justify-content-between align-items-start">
      <div class="qr-secion d-flex flex-row justify-content-center align-items-center">
        <img src="assets/imagen/footer/logoM.png" width="145" height="120" alt="logo Empresa">

      </div>
      <div class="d-flex flex-column justify-content-center align-items-center text-informacion">
        <span class="titulo-f">M. Electricidad SRL</span>
        <span>Maipu 447, Villa Mária</span>
        <span>Córdoba, Argentina</span>
        <span>Teléfono: +543534531921</span>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center div-3">
        <div class="d-flex flex-column align-items-center  iconos-footer mr-2">
          <a href="mailto:info@melectricidad.com.ar" class="text-white"><i class="fas fa-envelope"></i></a>
          <a href="" class="text-white"><i class="fab fa-instagram"></i></a>
          <a href="http://www.facebook.com/m.electricidadsrl" class="text-white"><i
            class="fab fa-facebook-square"></i></a>
        </div>
        <div class="align-items-center">
          <qr-code [value]="'https://melectricidad.com.ar'"></qr-code>
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="container-fluid traer-adelante">
  <script type='text/javascript'>TrustLogo("https://micuenta.donweb.com/img/sectigo_positive_sm.png", "CL1", "none");</script><a href="https://donweb.com/es-ar/certificados-ssl" id="comodoTL" title="Certificados SSL Argentina">Certificados SSL Argentina</a>
  <div class="d-flex justify-content-center align-items-center">
    <span class="text-dark">Hecho por: <a href="https://www.linkedin.com/in/ariel-brugiafredo-546893119/"
                                          target="_blank">Ariel Brugiafredo</a></span>
  </div>
</div>
