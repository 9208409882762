import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {CargandoService} from '../servicios/utilidades/cargando.service';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Injectable()
export class CargandoInterceptor implements HttpInterceptor{

  constructor(public cargandoService: CargandoService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.cargandoService.show();
    return next.handle(req).pipe(
      finalize(() => this.cargandoService.hide())
    );
  }
}
