import { Component} from '@angular/core';
import {Subject} from 'rxjs';
import {CargandoService} from '../../servicios/utilidades/cargando.service';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.css']
})
export class CargandoComponent  {

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.cargandoService.isLoading;
  constructor(private cargandoService: CargandoService){}


}
