import { Component, OnInit } from '@angular/core';
import {debounceTime, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {SubscriptionLike} from 'rxjs';
import {Login} from '../../modelo/usuario/login';
import {ProductoService} from '../../servicios/producto/producto.service';
import {UserloginService} from '../../servicios/usuario/userlogin.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.css']
})
export class CabeceraComponent implements OnInit {

  private subsprod: SubscriptionLike;
  public respuesta: any[];
  private subsLogin: SubscriptionLike;
  private userLogin: Login;
  public cargando:boolean;

  nombresIngresado = new FormControl();

  public textoBuscar:String;

  constructor(private prodSV: ProductoService, private userSV: UserloginService, private router:Router, private acRotuer:ActivatedRoute) {
    this.subsprod = null;
    this.subsLogin = null;
    this.respuesta = [];
    this.cargando=false;
    this.textoBuscar='';
  }
  ngOnInit(): void {
    this.subsLogin = this.userSV.currentUser.subscribe(res => {
      this.userLogin = res;

    });
    this.subsprod = this.nombresIngresado.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.respuesta = [];
          this.cargando = true;
        }),
        filter((value: string) => value.length > 2),
        switchMap(value => this.prodSV.listarNombresProductoWeb(value, this.userLogin!=null &&this.userLogin.cliCodigo!=null?this.userLogin.cliCodigo:'0').pipe(
          finalize(() => {
            this.cargando = false;
          })
        ))
      ).subscribe(res => {
        this.respuesta = res.data;
      });
  }

  displayFn(res: string): string {
    return res ? res : '';
  }

  ngOnDestroy(): void {
    this.subsprod.unsubscribe();
    this.subsLogin.unsubscribe();
  }

  buscar(){
    this.router.navigate(["/productos"],{queryParams:{B:this.textoBuscar},relativeTo:this.acRotuer});
  }


  setValueChange($event: MatAutocompleteSelectedEvent) {
    this.nombresIngresado.setValue($event.option.value);
  }

}
