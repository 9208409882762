<div class="modal-header modal-header">
  <div class="d-flex justify-content-center align-items-center">
      <h3>{{titulo}}</h3>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12 text-center">
      <p>{{texto}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <button mat-raised-button class="btn-success mr-1" (click)="activeModal.close('Recargar')">Recargar</button>
    </div>
  </div>
</div>
