import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {stringify} from "@angular/compiler/src/util";

@Injectable({
  providedIn: 'root'
})
export class PublicidadService {

  private headers = new HttpHeaders()
    .set("Content-Type", "application/json");

  constructor(private http: HttpClient) { }

  public traerPublicidad(idPublcidad:number):Observable<any>{
    const params = new HttpParams().set('funcion', 'traerPublicidad').set("id", ""+idPublcidad);
    return this.http.get(`${environment.url.gestionPublicidad}`, { headers:this.headers,params:params });
  }

  public modificarPublicidad(idPublcidad:number,nombre:string,isVideo:boolean,pubCodtext:string,pubCodnum:number,file: any,):Observable<any>{
    const formData = new FormData();
    formData.set('imagen',file);
    const params = new HttpParams()
      .set('funcion', 'modificarPublicidad')
      .set("id", ""+idPublcidad)
      .set("nombre", nombre)
      .set("isVideo", ""+isVideo)
      .set("pubCodtext", pubCodtext)
      .set("pubCodnum", ""+pubCodnum)
    ;
    return this.http.post(`${environment.url.gestionPublicidad}`, formData,{ params:params });
  }




}
