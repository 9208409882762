import {Component, OnDestroy, OnInit} from '@angular/core';
import {Login} from '../../modelo/usuario/login';
import {LoginService} from '../../servicios/usuario/login.service';
import {UserloginService} from '../../servicios/usuario/userlogin.service';
import {SubscriptionLike} from 'rxjs';
import {Categoria} from '../../modelo/producto/categoria';
import {ProductoService} from '../../servicios/producto/producto.service';
import {DeseadoService} from '../../servicios/producto/deseado.service';
import {CarritoService} from '../../servicios/pedido/carrito.service';
import {FormControl} from '@angular/forms';
import {debounceTime, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {
  public inicioSesion = false;
  public isAdmin = false;
  public currentUser: Login;
  public categoria: Categoria[];
  public cantidad: string;
  public cantidadCarrito: string;
  public catSelect: Categoria = null;

  public respuesta: any[];
  nombresIngresado = new FormControl();
  public cargando:boolean;
  public textoBuscar:String;


  private subs: SubscriptionLike[];
  private subsprod: SubscriptionLike;

  constructor(private login: LoginService, private userLogin: UserloginService, private prodSV: ProductoService, private desSV: DeseadoService, private carritoSV: CarritoService,private router:Router, private acRotuer:ActivatedRoute) {
    this.subs = [];
    this.subsprod = null;
    this.categoria = [];
    this.cantidad = '0';
    this.respuesta = [];
    this.cargando=false;
    this.textoBuscar='';
  }

  ngOnInit() {
    this.subs.push(
      this.userLogin.currentUser.subscribe(res => {
        this.inicioSesion = res != null;
        this.currentUser = res;

        this.isAdmin = res != null && res.usuario == 'Admin';
        if (res != null) {
          const id = res.idUsuario;
          this.desSV.listarCantidadDeseados(id);
          this.carritoSV.listarCantidadElementos(this.currentUser.cliCodigo);
        }
      }));
    this.subs.push(
      this.prodSV.listarCategoriasWeb().subscribe(res => {
        this.categoria = res.data;
      })
    );
    this.subs.push(
      this.desSV.cantidad$.subscribe(res => {
        this.cantidad = res;
      }));
    this.subs.push(
      this.carritoSV.cantidad$.subscribe(res => {
        this.cantidadCarrito = res;
      }));
    this.subsprod = this.nombresIngresado.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.respuesta = [];
          this.cargando = true;
        }),
        filter((value: string) => value.length > 2),
        switchMap(value => this.prodSV.listarNombresProductoWeb(value, this.currentUser!=null &&this.currentUser.cliCodigo!=null?this.currentUser.cliCodigo:'0').pipe(
          finalize(() => {
            this.cargando = false;
          })
        ))
      ).subscribe(res => {
        this.respuesta = res.data;
      });
  }

  cerrarSion() {
    this.subs.push(
      this.login.cerrarSesion().subscribe(res => {
        this.inicioSesion = res.status_message != 'Exito';
      }));
    this.userLogin.logout();
    this.cantidad = '0';
    this.carritoSV.limpiarElemento();

  }

  ngOnDestroy(): void {
    this.subsprod.unsubscribe();
    this.subs.forEach(sub => sub.unsubscribe());
  }


  displayFn(res: string): string {
    return res ? res : '';
  }

  buscar(){
    console.log(this.textoBuscar);
    this.router.navigate(["/productos"],{queryParams:{B:this.textoBuscar},relativeTo:this.acRotuer});
  }

  setValueChange($event: MatAutocompleteSelectedEvent) {
    this.nombresIngresado.setValue($event.option.value);
  }
}
