import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {FormaPago} from '../../modelo/pedido/forma-pago';
import {Transporte} from '../../modelo/pedido/transporte';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  constructor(private http: HttpClient) { }
  listarPedido():Observable<any>{
    const params = new HttpParams().set('funcion', 'listarPedidos');
    return this.http.get(`${environment.url.gestionPedido}`, { params });
  }
  listarPedidoUsuario(cliCodio:string):Observable<any>{
    const params = new HttpParams().set('funcion', 'listarPedidosUsuario').set("cli_codigo",cliCodio);
    return this.http.get(`${environment.url.gestionPedido}`, { params });
  }

  generarPedido(formaPagoSelect: FormaPago, transporteSelect: string, mail: string, observaciones: string, condicionVta: string, usuario:string,clicodigo:string):Observable<any> {
    const params = new HttpParams()
      .set('funcion', 'generarPedido')
      .set("transporte",transporteSelect)
      .set("mail",mail)
      .set("fpago",formaPagoSelect.for_codigo.toString())
      .set("condicionvta",condicionVta)
      .set("comentario",observaciones)
      .set("usuario",usuario)
      .set("cli_codigo",clicodigo)

    ;
    return this.http.get(`${environment.url.gestionPedido}`, { params });
  }

  actualizarPedido(idPedido: string, accion: string, preferenceId: string, collectionId: string):Observable<any> {
    const params = new HttpParams()
      .set('funcion', 'actualizarPedido')
      .set("idPedido",idPedido)
      .set("accion",accion)
      .set("preferenceId",preferenceId)
      .set("collectionId",collectionId)
    ;
    return this.http.get(`${environment.url.gestionPedido}`, { params });
  }
}
