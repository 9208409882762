import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeseadoService {
  private cantidad: BehaviorSubject<string> ;
  public cantidad$: Observable<string> ;
  constructor(private http: HttpClient) {
    this.cantidad = new BehaviorSubject<string>("0");
    this.cantidad$=this.cantidad.asObservable();
  }

  listarDeseados(idCliente: string, clicodigo: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'listarDeseados').set('idCliente', idCliente).set('clicodigo',clicodigo);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  listarCantidadDeseados(idCliente: string) {
    const params = new HttpParams().set('funcion', 'traerCantidadDeseado').set('idCliente', idCliente);
    return this.http.get<any>(`${environment.url.gestionProducto}`, { params }).subscribe(res=>{
        this.cantidad.next(res.data);
    });
  }

  aniadirDeseado(art_codtex: string, art_codnum: number, idUsuario: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'aniadirDeseado').set("art_codtext",art_codtex).set('art_codnum',''+art_codnum).set("idUsuario",idUsuario);
    return this.http.get(`${environment.url.gestionProducto}`, { params });
  }

  eliminarDeseado(art_codtex: string, art_codnum: number, idUsuario: string):Observable<any> {
    const params = new HttpParams().set('funcion', 'eliminarDeseado').set("art_codtext",art_codtex).set('art_codnum',''+art_codnum).set("idUsuario",idUsuario);
      return this.http.get<any>(`${environment.url.gestionProducto}`, { params });
  }


}
